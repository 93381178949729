import React from 'react';
import { Alert, Spinner } from 'emerald-ui';
import PropTypes from 'prop-types';
import { SpinnerContainer } from '../CommonStyledComponents';

const ComponentLoader = ({ pastDelay, error, timedOut }) => {
  return (
    <div>
      {pastDelay && (
        <SpinnerContainer>
          <Spinner size="lg" color="info" />
        </SpinnerContainer>
      )}
      {(error || timedOut) && (
        <Alert color="danger" className="m-t">
          There was a problem loading the page.
        </Alert>
      )}
    </div>
  );
};

ComponentLoader.propTypes = {
  pastDelay: PropTypes.bool,
  error: PropTypes.objectOf(PropTypes.any),
  timedOut: PropTypes.bool,
};

ComponentLoader.defaultProps = {
  pastDelay: false,
  error: null,
  timedOut: false,
};

export default ComponentLoader;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import ComponentLoader from '../ComponentLoader';
import NotFound from '../NotFound';

const LoadComponent = (opts) => Loadable(Object.assign({ loading: ComponentLoader }, opts));

const CourseFlow = LoadComponent({
  loader: () => import('../../containers/CourseFlow'),
});

const StudentLoaderWrapper = (Component) => {
  return LoadComponent({
    loader: () => import('../../containers/StudentLoader'),
    render(loaded, props) {
      const StudentLoader = loaded.default;
      return <StudentLoader {...props} CurrentComponent={Component} />;
    },
  });
};

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/:id" component={StudentLoaderWrapper(CourseFlow)} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  </main>
);

export default Main;

import TagManager from 'react-gtm-module';
import settings from '../config/settings';

const { isReactGTMEnabled, GTMTrackingId } = settings;
const { REACT_APP_ENV = 'development' } = process.env;

export const InitGTMTracker = () => {
  if (isReactGTMEnabled && !!GTMTrackingId && REACT_APP_ENV !== 'development') {
    const tagManagerArgs = {
      gtmId: GTMTrackingId,
    };

    TagManager.initialize(tagManagerArgs);
  }
};

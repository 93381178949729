/* istanbul ignore file */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { userActions, userSelectors } from '../store/loggedInUser';
import App from '../components/App';

function mapStateToProps(state) {
  const loggedInUser = userSelectors(state);
  return {
    user: loggedInUser.getUser(),
    userFetchStatus: loggedInUser.getFetchStatus(),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchLoggedInUser: userActions.fetch,
  })(App)
);

import styled, { css } from 'styled-components';

const SkeletonBar = styled.div`
  ${({ width, height, backgroundColor }) => css`
    width: ${width || '100%'};
    height: ${height || '20px'};
    background-color: ${backgroundColor || '#f3f3f3'};
  `}
`;
SkeletonBar.displayName = 'SkeletonBar';

export default SkeletonBar;

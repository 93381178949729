import React from 'react';
import { Modal, Button } from 'emerald-ui';
import PropTypes from 'prop-types';

import warningIcon from './icon-warning.svg';
import errorIcon from './icon-error.svg';
import successIcon from './icon-success.svg';
import infoIcon from './icon-info.svg';

import { Image, Title, Span } from './AlertBox.styled';
import { ModalContainer } from '../CommonStyledComponents';

const AlertBox = ({
  show,
  type,
  title,
  text,
  imageIcon,
  imageIconStyle,
  onConfirm,
  confirmColor,
  confirmShape,
  onCancel,
  cancelColor,
  cancelShape,
  confirmButtonText,
  cancelButtonText,
  disableConfirmButton,
  loadingConfirmButton,
  darkMode,
}) => {
  const modalType = {
    warning: warningIcon,
    error: errorIcon,
    success: successIcon,
    info: infoIcon,
    custom: imageIcon,
  };
  const iconImage = modalType[type] || infoIcon;

  return (
    <ModalContainer darkMode={darkMode} show={show} className="text-center">
      <Modal.Body>
        <Image src={iconImage} alt={type} style={imageIconStyle || {}} />
        <Title darkMode={darkMode}>{title}</Title>
        <Span darkMode={darkMode}>{text}</Span>
      </Modal.Body>
      <Modal.Footer className="text-center">
        {cancelButtonText && (
          <Button id="closeAlert" onClick={onCancel} shape={cancelShape} color={cancelColor}>
            {cancelButtonText}
          </Button>
        )}
        <Button
          id="confirmAlert"
          onClick={onConfirm}
          shape={confirmShape}
          color={confirmColor}
          disabled={disableConfirmButton}
          loading={loadingConfirmButton}
        >
          {confirmButtonText || 'OK'}
        </Button>
      </Modal.Footer>
    </ModalContainer>
  );
};

AlertBox.propTypes = {
  confirmColor: PropTypes.string,
  cancelColor: PropTypes.string,
  confirmShape: PropTypes.string,
  cancelShape: PropTypes.string,
  show: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageIcon: PropTypes.string,
  imageIconStyle: PropTypes.objectOf(PropTypes.string),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableConfirmButton: PropTypes.bool,
  loadingConfirmButton: PropTypes.bool,
  darkMode: PropTypes.bool,
};

AlertBox.defaultProps = {
  confirmColor: 'primary',
  cancelColor: 'info',
  confirmShape: 'classic',
  cancelShape: 'flat',
  show: false, // Visibility of the modal
  type: 'warning',
  title: '',
  text: '',
  imageIcon: warningIcon,
  imageIconStyle: {},
  onCancel: () => {},
  confirmButtonText: '',
  cancelButtonText: '',
  disableConfirmButton: false,
  loadingConfirmButton: false,
  darkMode: false,
};

export default AlertBox;

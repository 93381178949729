import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';
import axios from '../../api/axiosConfig';
import settings from '../../config/settings';
import { useAlertBox } from '../AlertBox';
import { errorHasStatusCode } from '../../helpers/errorsHelper';
import DomPurify from 'dompurify';

export function SessionModal(props) {
  const alert = useAlertBox();
  const { match, darkMode } = props;
  const { params } = match;

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(null, (err) => {
      if (errorHasStatusCode(err, 401)) {
        alert.display({
          type: 'warning',
          title: "Seems you've been a long time inactive!",
          text: <h3>Do you want to continue editing the course?</h3>,
          darkMode,
          confirmButtonText: 'CONTINUE',
          cancelButtonText: 'GO BACK',
          onConfirm: () => window.location.reload(),
          onCancel: () => {
            const id = DomPurify.sanitize(params.id);
            const url = settings.homeButtonLinkUrl.replace(':id', id);
            window.location.assign(url);
          },
        });
      }
      return Promise.reject(err);
    });
    return () => axios.interceptors.response.eject(interceptor);
  }, [alert, params.id, darkMode]);

  return <>{alert.content()}</>;
}

SessionModal.propTypes = {
  match: propTypes.shape({
    params: propTypes.any,
  }).isRequired,
  darkMode: propTypes.bool.isRequired,
};

export default withRouter(SessionModal);

/* istanbul ignore file */
export default function (globalState) {
  const state = globalState.loggedInUser;

  const getUser = () => state.user;
  const getFetchStatus = () => state.fetchStatus;
  const getErrors = () => state.errorStatus;

  return {
    getUser,
    getFetchStatus,
    getErrors,
  };
}

import React from 'react';
import { Overlay, Paragraph, Image, Title, Link } from './NotFound.styled';
import settings from '../../config/settings';
import { withTransaction } from '@elastic/apm-rum-react';

const NotFound = () => (
  <Overlay>
    <Image src={`https://${settings.assetsBucketName}.s3.amazonaws.com/images/404.svg`} alt="Not available" />
    <Title>Did you get lost?</Title>
    <Paragraph>
      We can&apos;t find what you&apos;re looking for. Maybe you&apos;re not in the right direction.
    </Paragraph>
    <Link href={settings.notAvailableBackUrl}>Go Back</Link>
  </Overlay>
);

export default withTransaction('NotFound', 'page_component')(NotFound);

import React, { Component } from 'react';
import { Alert, Spinner } from 'emerald-ui';
import Intercom from 'react-intercom';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';
import Main from '../Main';
import SessionModal from '../../containers/SessionModal';
import Unauthorized from '../Unauthorized';
import NotFound from '../NotFound';
import settings from '../../config/settings';
import { injectIntercomConfiguration } from '../../config/codeInjections/functions';
import authAPI from '../../api/auth';
import AlertBox from '../AlertBox';
import { SpinnerContainer } from '../CommonStyledComponents';
import { getErrorMessage } from '../../helpers/errorsHelper';
import DomPurify from 'dompurify';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intercomSettings: {},
      isAuthenticating: false,
      authenticationStatus: null,
      errorMessage: '',
    };

    this.authStatus = {
      SUCCESS: 'success',
      FAILED: {
        UNAUTHORIZED: 'unauthorized',
        COURSE_NOT_FOUND: 'courseNotFound',
        STUDENT_IN_PROGRESS: 'studentInProgress',
        OTHER_REASON: 'other',
      },
    };
  }

  createURL = (studentId, locationSearch) => {
    const urlParams = new URLSearchParams(locationSearch);
    const googleAnalyticsParam = urlParams.get('_gl');
    let newUrl = `/${studentId}`;
    if (googleAnalyticsParam) {
      newUrl = `/${studentId}?_gl=${googleAnalyticsParam}`;
    }
    return newUrl;
  };

  componentDidMount() {
    this.moveIntercomIcon();
    this.authenticateUser();
  }

  componentDidUpdate(prevProps) {
    const { userFetchStatus } = this.props;
    if (settings.isIntercomEnabled && prevProps.userFetchStatus === 'loading' && userFetchStatus === 'loaded') {
      this.configureIntercom();
    }
  }

  configureIntercom = () => {
    let intercomSettings = {
      appId: settings.intercomAppID,
    };
    intercomSettings = injectIntercomConfiguration(intercomSettings);
    this.setState({ intercomSettings });
  };

  moveIntercomIcon = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (settings.isIntercomEnabled && width <= 575) {
      const tIntercom = setInterval(() => {
        const intercomObject = document.getElementsByClassName('intercom-launcher-frame');
        if (intercomObject.length > 0) {
          intercomObject[0].setAttribute('style', 'bottom: 57px !important;');
          intercomObject[0].classList.add('move-intercom');
          clearInterval(tIntercom);
        }
      }, 500);
    }
  };

  authenticateUser = () => {
    const { fetchLoggedInUser, location, history } = this.props;
    let studentId;
    if (location.pathname !== '/') {
      studentId = location.pathname.substring(1);
    }
    const params = QueryString.parse(location.search);
    fetchLoggedInUser(studentId);

    this.setState({ isAuthenticating: true });

    authAPI
      .getAccessToken({ studentId, ...params })
      .then((res) => {
        const newUrl = this.createURL(res.data.studentId, location.search);
        history.push(newUrl);
        this.setState({ authenticationStatus: this.authStatus.SUCCESS, isAuthenticating: false });
      })
      .catch((error) => {
        const defaultAuthenticationStatus = {
          401: this.authStatus.FAILED.UNAUTHORIZED,
          404: this.authStatus.FAILED.COURSE_NOT_FOUND,
        };
        let authenticationStatus = this.authStatus.FAILED.OTHER_REASON;
        const errorMessage = getErrorMessage(error, 'There was an error while getting access to the course.');

        const { response } = error;
        if (response && response.status) {
          authenticationStatus = defaultAuthenticationStatus[response.status] || authenticationStatus;
          if (response.status === 401 && response.data && response.data.reason === 'NOT_AUTHENTICATED') {
            authenticationStatus = null;
            window.location.href = settings.clientLoginUrl.replace(':returnUrl', window.location.href);
          }
          if (response.status === 400 && errorMessage === 'STUDENT_IN_PROGRESS') {
            const newUrl = this.createURL(response.data.studentId, window.location.search);
            history.push(newUrl);
            authenticationStatus = this.authStatus.FAILED.STUDENT_IN_PROGRESS;
          }
        }

        this.setState({
          authenticationStatus,
          errorMessage,
          isAuthenticating: false,
        });
      });
  };

  loadStudentInProgress = () => {
    this.authenticateUser();
  };

  render() {
    const { userFetchStatus, match } = this.props;
    const { intercomSettings, isAuthenticating, authenticationStatus, errorMessage } = this.state;
    const clientHomeLink = settings.homeButtonLinkUrl.replace(':id', match.params.id);

    if (userFetchStatus === 'notLoaded' || userFetchStatus === 'loading') {
      return null;
    }

    if (userFetchStatus === 'failed') {
      return (
        <Alert color="danger" className="m-t">
          There was an error loading the user information
        </Alert>
      );
    }

    return (
      <div>
        {isAuthenticating && (
          <SpinnerContainer>
            <Spinner size="lg" color="info" />
          </SpinnerContainer>
        )}

        {authenticationStatus === this.authStatus.FAILED.UNAUTHORIZED && <Unauthorized backLink={clientHomeLink} />}
        {authenticationStatus === this.authStatus.FAILED.COURSE_NOT_FOUND && <NotFound />}

        <AlertBox
          show={authenticationStatus === this.authStatus.FAILED.STUDENT_IN_PROGRESS}
          type="warning"
          title="Course in progress"
          text="You'll be redirected to continue taking this course"
          confirmButtonText="OK, GOT IT"
          onConfirm={this.loadStudentInProgress}
          disableConfirmButton={isAuthenticating}
          loadingConfirmButton={isAuthenticating}
        />

        {authenticationStatus === this.authStatus.FAILED.OTHER_REASON && (
          <Alert color="danger" className="m-t">
            <span>
              {errorMessage} {clientHomeLink && <a href={DomPurify.sanitize(clientHomeLink)}> Go back</a>}
            </span>
          </Alert>
        )}

        {authenticationStatus === this.authStatus.SUCCESS && (
          <React.Fragment>
            <SessionModal />
            <Main />
            {settings.isIntercomEnabled && <Intercom {...intercomSettings} />}
          </React.Fragment>
        )}
      </div>
    );
  }
}

App.propTypes = {
  userFetchStatus: PropTypes.string.isRequired,
  fetchLoggedInUser: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.any,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  history: PropTypes.object,
};

App.defaultProps = {
  match: null,
  location: null,
};

export default App;

/* istanbul ignore file */
import { combineReducers } from 'redux';
import at from '../actions/types';

export const student = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_STUDENT_SUCCESS: {
      if (action.student) {
        return action.student;
      }
      return state;
    }
    case at.SAVE_CONTENT_PAGES_REMAINING_TIME_SUCCESS: {
      const { data } = action;
      let found = false;
      const contentPagesRemainingTime = state.contentPagesRemainingTime.map((item) => {
        if (item.contentPageId === data.studentData.contentPageId) {
          found = true;
          return { ...item, remainingTime: data.studentData.remainingTime };
        }
        return item;
      });
      if (found) {
        return { ...state, contentPagesRemainingTime };
      }
      return {
        ...state,
        contentPagesRemainingTime: state.contentPagesRemainingTime.concat(data.studentData),
      };
    }
    case at.UPDATE_AVERAGE_SCORE_STATE: {
      const { totalScore } = action;

      const isCompletingCourse = !state.dateCompleted && (state.payment || state.courseVersion.price === 0);
      const dateCompleted = isCompletingCourse ? new Date().toString() : state.dateCompleted;

      return {
        ...state,
        totalScore,
        isPassed: true,
        dateCompleted,
      };
    }
    case at.GENERATE_CERTIFICATE_SUCCESS: {
      const { data } = action;

      const certificate = { ...state.certificate };
      certificate.url = data.certificateUrl;

      return {
        ...state,
        certificate,
      };
    }
    case at.UPDATE_REVIEWS_STATE: {
      const { data } = action;

      const courseVersion = { ...state.courseVersion };
      let reviews = courseVersion.reviews || [];
      const newReview = { courseVersionId: data.courseVersionId, ...data.reviewData };
      reviews = reviews.concat(newReview);
      courseVersion.reviews = reviews;
      return { ...state, courseVersion };
    }
    case at.SET_LICENSEE_ACCEPTANCE_TERMS: {
      const { data } = action;

      const newLicensee = { ...state.licensee };
      newLicensee.acceptanceTerms = { ...data };

      return { ...state, licensee: { ...newLicensee } };
    }
    case at.SET_COMPLETION_DATE: {
      return { ...state, dateCompleted: new Date().toString() };
    }
    case at.SET_STUDENT_PAYMENT: {
      const { payment } = action;
      return { ...state, payment };
    }
    case at.UPDATE_CURRENT_STEP_SUCCESS: {
      const { data } = action;
      return { ...state, currentStep: data.currentStep };
    }
    default:
      return state;
  }
};

export const fetchStudentStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_STUDENT_REQUEST: {
      return 'loading';
    }
    case at.SET_STUDENT_LOADED_STATUS:
    case at.FETCH_STUDENT_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_STUDENT_FAILURE: {
      return 'failed';
    }
    case at.FETCH_STUDENT_NOT_FOUND: {
      return 'notFound';
    }
    default:
      return state;
  }
};

export const generateCertificateStatus = (state = 'notCreated', action) => {
  switch (action.type) {
    case at.GENERATE_CERTIFICATE_REQUEST: {
      return 'creating';
    }
    case at.GENERATE_CERTIFICATE_SUCCESS: {
      return 'created';
    }
    case at.GENERATE_CERTIFICATE_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_GENERATE_CERTIFICATE_STATUS: {
      return 'notCreated';
    }
    default:
      return state;
  }
};

export const errorStatus = (state, action) => {
  const errors = {};

  if (action.type === at.REQUEST_FAILURE) {
    errors[action.requestFailed.split('/')[1]] = action.message;
  }

  return errors;
};

export default combineReducers({
  student,
  fetchStudentStatus,
  errorStatus,
  generateCertificateStatus,
});

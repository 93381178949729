/* istanbul ignore file */
import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import student from './student';

export default function* root() {
  yield takeLatest(at.FETCH_STUDENT_REQUEST, student.fetchStudentById);
  yield takeLatest(at.UPDATE_CURRENT_STEP_REQUEST, student.updateCurrentStep);
  yield takeLatest(at.SAVE_CONTENT_PAGES_REMAINING_TIME_REQUEST, student.saveContentPagesRemainingTime);
  yield takeLatest(at.GENERATE_CERTIFICATE_REQUEST, student.generateCertificate);
}

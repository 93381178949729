/* istanbul ignore file */
import axios from './axiosConfig';
import settings from '../config/settings';

export const fetchContentPages = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/contentPagesVersions`,
    method: 'GET',
    params: data,
    withCredentials: true,
  });
};

/* istanbul ignore file */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import reducers from './rootReducer';
import rootSaga from './rootSaga';

// This line allows to use redux-devtools on the browser
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const logRocketMiddleware = LogRocket.reduxMiddleware();

const middlewares = [sagaMiddleware, logRocketMiddleware];

export const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));
sagaMiddleware.run(rootSaga);

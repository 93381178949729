/* istanbul ignore file */
import { combineReducers } from 'redux';
import at from '../actions/types';

export const darkMode = (state = false, action) => {
  switch (action.type) {
    case at.SET_DARK_MODE_REQUEST: {
      return !state;
    }
    default:
      return state;
  }
};

export default combineReducers({ darkMode });

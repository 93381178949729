/* istanbul ignore file */
import { combineReducers } from 'redux';
import at from '../actions/types';
import studentActions from '../../student/actions/types';

export const steps = (state = [], action) => {
  switch (action.type) {
    case at.FETCH_COURSE_CONTENT_SUCCESS: {
      const { steps } = action;

      const contentPages = steps[0].data.map((page) => {
        return { ...page, type: 'page' };
      });

      const orderedTests = [...steps[1].data].sort((a, b) => a.index - b.index);
      const lastTest = orderedTests[orderedTests.length - 1];

      const tests = steps[1].data.map((test) => {
        const questionsList = [...test.questions];
        const questions = questionsList.map((question) => {
          return {
            ...question,
            type: 'singleChoiceQuestion',
          };
        });

        return {
          ...test,
          type: 'test',
          score: null,
          questions,
          isLastTest: test.index === lastTest.index,
        };
      });

      const surveys = steps[2].data.map((survey) => {
        const { singleChoiceQuestions, shortAnswerQuestions } = survey;

        const singleChoiceQuestionsList = singleChoiceQuestions.map((question) => {
          return Object.assign({}, question, {
            type: 'singleChoiceQuestion',
          });
        });

        const shortAnswerQuestionsList = shortAnswerQuestions.map((question) => {
          return Object.assign({}, question, {
            type: 'shortAnswerQuestion',
            responseText: '',
          });
        });

        const questions = [...singleChoiceQuestionsList, ...shortAnswerQuestionsList].sort((a, b) => a.index - b.index);

        return {
          ...survey,
          type: 'survey',
          questions,
        };
      });

      const quizzes = steps[3].data.map((quiz) => {
        const questionsList = [...quiz.questions];
        const questions = questionsList.map((question) => {
          return {
            ...question,
            type: 'singleChoiceQuestion',
          };
        });

        return { ...quiz, type: 'quiz', questions };
      });

      const orderedStandardizedPages = [...steps[4].data].sort((a, b) => a.index - b.index);
      const lastStandardizedPage = orderedStandardizedPages[orderedStandardizedPages.length - 1];

      const standardizedPages = steps[4].data.map((standardizedPage) => {
        const completion =
          standardizedPage.completions && standardizedPage.completions.length ? standardizedPage.completions[0] : {};
        const { launchUrl, dateCompleted, score } = completion;
        return {
          ...standardizedPage,
          type: 'standardizedPage',
          launchUrl,
          dateCompleted,
          score,
          isLastPage: standardizedPage.index === lastStandardizedPage.index,
        };
      });

      let content = [...contentPages, ...tests, ...surveys, ...quizzes, ...standardizedPages];
      content = content.sort((a, b) => a.index - b.index);
      return content;
    }
    case at.CREATE_STEPS: {
      return action.steps;
    }
    case at.SELECT_STEP: {
      const { index } = action;
      const newSteps = state.map((step, i) => {
        if (i === index) {
          return { ...step, selected: true, seen: true };
        }
        return { ...step, selected: false };
      });
      return newSteps;
    }
    case at.SELECT_ANSWER: {
      const { answerId, questionIndex, currentStepIndex } = action.payload;
      const newSteps = [...state];
      const { questions } = newSteps[currentStepIndex];
      const { answers } = questions[questionIndex];

      const newAnswers = answers.map((answer) => {
        if (answerId === answer._id) {
          return {
            ...answer,
            selected: true,
          };
        }

        return {
          ...answer,
          selected: false,
        };
      });

      newSteps[currentStepIndex].questions[questionIndex].answers = newAnswers;

      return newSteps;
    }
    case at.SELECT_QUESTION: {
      const { questionIndex, currentStepIndex } = action.payload;
      const newSteps = [...state];
      const { questions } = newSteps[currentStepIndex];
      questions[questionIndex].answered = true;

      return newSteps;
    }
    case at.UPDATE_SHORT_ANSWER_RESPONSE: {
      const { responseText, questionIndex, currentStepIndex } = action.payload;
      const newSteps = [...state];
      const { questions } = newSteps[currentStepIndex];
      const newQuestions = questions.map((question, index) => {
        if (index === questionIndex) {
          return { ...question, responseText };
        }
        return { ...question };
      });

      newSteps[currentStepIndex].questions = newQuestions;
      return newSteps;
    }
    case at.UPDATE_TEST_ANSWERS_STATE: {
      const { score, testId, rightAnswers } = action.data;

      const newSteps = state.map((step) => {
        if (step.type === 'test' && step._id === testId) {
          const isPassed = score >= step.passPercentage;

          let newQuestions = step.questions;
          if (isPassed) {
            newQuestions = step.questions.map((question) => {
              const currentRightAnswer = rightAnswers.find((element) => element.questionId === question._id);

              const newAnswers = question.answers.map((answer) => {
                return { ...answer, isRight: currentRightAnswer.answerId === answer._id };
              });

              return { ...question, answers: newAnswers };
            });
          }

          return {
            ...step,
            isPassed,
            score,
            skip: isPassed,
            done: isPassed,
            questions: newQuestions,
          };
        }
        return { ...step };
      });

      return newSteps;
    }
    case at.SAVE_QUIZ_ANSWERS_SUCCESS: {
      const { selectedStepIndex } = action.data;
      const newSteps = [...state];

      newSteps[selectedStepIndex].skip = true;
      newSteps[selectedStepIndex].isPassed = true;
      newSteps[selectedStepIndex].done = true;

      return newSteps;
    }
    case at.CLEAR_QUIZ_SELECTED_ANSWERS: {
      const { currentStepIndex } = action;
      const newSteps = [...state];
      const { questions } = newSteps[currentStepIndex];

      const newQuestions = questions.map((question) => {
        const answers = question.answers.map((answer) => {
          return { ...answer, selected: false };
        });

        return {
          ...question,
          answers,
          answered: false,
        };
      });

      newSteps[currentStepIndex].questions = newQuestions;

      return newSteps;
    }
    case at.SAVE_SURVEY_ANSWERS_SUCCESS: {
      const { selectedStepIndex } = action.data;
      const newSteps = [...state];

      newSteps[selectedStepIndex].skip = true;
      newSteps[selectedStepIndex].isPassed = true;
      newSteps[selectedStepIndex].done = true;

      return newSteps;
    }
    case studentActions.UPDATE_REVIEWS_STATE: {
      const newSteps = state.map((step) => {
        if (step.type === 'rating') {
          return { ...step, skip: true, done: true, isPassed: true };
        }
        return step;
      });
      return newSteps;
    }
    case at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS: {
      const { launchUrl, standardizedPageId } = action.data;

      const newSteps = state.map((step) => {
        if (step.type === 'standardizedPage' && step.standardizedPage === standardizedPageId) {
          return { ...step, launchUrl };
        }
        return step;
      });
      return newSteps;
    }
    case at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS: {
      const { standardizedPageId, launchUrl, dateCompleted } = action.data;

      const newSteps = state.map((step) => {
        if (step.type === 'standardizedPage' && step.standardizedPage === standardizedPageId) {
          return { ...step, launchUrl, dateCompleted };
        }
        return step;
      });
      return newSteps;
    }
    case at.UPDATE_STANDARDIZED_PAGE_VERSION_STATE: {
      const { data } = action;
      const newSteps = state.map((step) => {
        if (step.type === 'standardizedPage' && step._id === data.standardizedPageVersionId) {
          return { ...step, ...data.standardizedPageVersionData, done: true };
        }
        return step;
      });
      return newSteps;
    }
    default:
      return state;
  }
};

export const fetchCourseContentStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_COURSE_CONTENT_REQUEST: {
      return 'loading';
    }
    case at.FETCH_COURSE_CONTENT_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_COURSE_CONTENT_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const updateTestAnswersStateStatus = (state = 'notUpdated', action) => {
  switch (action.type) {
    case at.UPDATE_TEST_ANSWERS_STATE: {
      return 'updated';
    }
    case at.CLEAR_UPDATE_TEST_ANSWERS_STATE_STATUS: {
      return 'notUpdated';
    }
    default: {
      return state;
    }
  }
};

export const createCourseStepsStatus = (state = 'notCreated', action) => {
  switch (action.type) {
    case at.CREATE_STEPS: {
      return 'created';
    }
    default: {
      return state;
    }
  }
};

export const saveQuizAnswersStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_QUIZ_ANSWERS_REQUEST: {
      return 'saving';
    }
    case at.SAVE_QUIZ_ANSWERS_SUCCESS: {
      return 'saved';
    }
    case at.SAVE_QUIZ_ANSWERS_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_QUIZ_ANSWERS_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const saveSurveyAnswersStatus = (state = 'notSaved', action) => {
  switch (action.type) {
    case at.SAVE_SURVEY_ANSWERS_REQUEST: {
      return 'saving';
    }
    case at.SAVE_SURVEY_ANSWERS_SUCCESS: {
      return 'saved';
    }
    case at.SAVE_SURVEY_ANSWERS_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_SAVE_SURVEY_ANSWERS_STATUS: {
      return 'notSaved';
    }
    default:
      return state;
  }
};

export const createStandardizedPageLaunchUrlStatus = (state = 'notCreated', action) => {
  switch (action.type) {
    case at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST: {
      return 'creating';
    }
    case at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS: {
      return 'created';
    }
    case at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_STANDARDIZED_PAGE_LAUNCH_URL_STATUS: {
      return 'notCreated';
    }
    default:
      return state;
  }
};

export const fetchStandardizedPageLaunchUrlStatus = (state = 'notLoaded', action) => {
  switch (action.type) {
    case at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST: {
      return 'loading';
    }
    case at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE: {
      return 'failed';
    }
    case at.CLEAR_FETCH_STANDARDIZED_PAGE_LAUNCH_URL_STATUS: {
      return 'notLoaded';
    }
    default:
      return state;
  }
};

export const errorStatus = (state, action) => {
  const errors = {};

  if (action.type === at.REQUEST_FAILURE) {
    errors[action.requestFailed.split('/')[1]] = action.message;
  }

  return errors;
};

export default combineReducers({
  steps,
  fetchCourseContentStatus,
  createCourseStepsStatus,
  updateTestAnswersStateStatus,
  saveQuizAnswersStatus,
  saveSurveyAnswersStatus,
  errorStatus,
  createStandardizedPageLaunchUrlStatus,
  fetchStandardizedPageLaunchUrlStatus,
});

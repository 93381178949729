/* istanbul ignore file */
const implementations = require('./implementations');

const overrideFunction = ({ functionName, functionBody }) => {
  if (implementations && Object.keys(implementations).length && implementations[functionName]) {
    return implementations[functionName];
  }
  return functionBody;
};

const injectIntercomConfiguration = (intercomSettings) => {
  return intercomSettings;
};

const injectAcceptanceTermsParagraph = () => {
  return '';
};

module.exports = {
  injectIntercomConfiguration: overrideFunction({
    functionName: 'injectIntercomConfiguration',
    functionBody: injectIntercomConfiguration,
  }),
  injectAcceptanceTermsParagraph: overrideFunction({
    functionName: 'injectAcceptanceTermsParagraph',
    functionBody: injectAcceptanceTermsParagraph,
  }),
};

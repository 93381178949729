import React from 'react';
import styled, { css } from 'styled-components';

export const Image = styled.img`
  width: 80px;
  height: 80px;
`;
Image.displayName = 'Image';

export const Title = styled.h2`
  ${({ theme, darkMode }) => css`
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: 600;
    color: ${darkMode ? '#fff' : theme.color.gray.variant5};
  `};
`;
Title.displayName = 'Title';

export const Span = styled((props) => {
  const newProps = { ...props };
  delete newProps.darkMode;

  return <span {...newProps} />;
})`
  ${({ theme, darkMode }) => css`
    h3 {
      color: ${darkMode ? '#fff' : theme.color.gray.variant5};
    }
  `};
`;
Span.displayName = 'Span';

const logger = require('@condor-labs/logger');

export const errorHasStatusCode = (error, statusCode) => {
  return error.response && error.response.status === statusCode;
};

export const getErrorMessage = (error, defaultErrorMessage) => {
  let errorMessage = defaultErrorMessage;
  if (error && error.response && error.response.data) {
    const { errors, message } = error.response.data;
    if (errors && errors.length) {
      errorMessage = errors[0].message;
    } else if (message) {
      errorMessage = message;
    }
  } else {
    logger.error(error);
  }
  return errorMessage;
};

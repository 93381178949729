/* istanbul ignore file */
import axios from './axiosConfig';
import settings from '../config/settings';

export const fetchSurveys = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/surveysVersions`,
    method: 'GET',
    params: data,
    withCredentials: true,
  });
};

export const saveSurveyAnswers = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/surveysVersions/${data.surveyId}/completions`,
    method: 'POST',
    data: data.body,
    withCredentials: true,
  });
};

/* istanbul ignore file */
export default {
  FETCH_STUDENT_REQUEST: 'student/FETCH_STUDENT_REQUEST',
  FETCH_STUDENT_SUCCESS: 'student/FETCH_STUDENT_SUCCESS',
  FETCH_STUDENT_FAILURE: 'student/FETCH_STUDENT_FAILURE',
  FETCH_STUDENT_NOT_FOUND: 'student/FETCH_STUDENT_NOT_FOUND',
  SET_STUDENT_LOADED_STATUS: 'student/SET_STUDENT_LOADED_STATUS',

  UPDATE_CURRENT_STEP_REQUEST: 'student/UPDATE_CURRENT_STEP_REQUEST',
  UPDATE_CURRENT_STEP_SUCCESS: 'student/UPDATE_CURRENT_STEP_SUCCESS',
  UPDATE_CURRENT_STEP_FAILURE: 'student/UPDATE_CURRENT_STEP_FAILURE',

  SAVE_CONTENT_PAGES_REMAINING_TIME_REQUEST: 'student/SAVE_CONTENT_PAGES_REMAINING_TIME_REQUEST',
  SAVE_CONTENT_PAGES_REMAINING_TIME_SUCCESS: 'student/SAVE_CONTENT_PAGES_REMAINING_TIME_SUCCESS',

  UPDATE_AVERAGE_SCORE_STATE: 'student/UPDATE_AVERAGE_SCORE_STATE',

  GENERATE_CERTIFICATE_REQUEST: 'student/GENERATE_CERTIFICATE_REQUEST',
  GENERATE_CERTIFICATE_SUCCESS: 'student/GENERATE_CERTIFICATE_SUCCESS',
  GENERATE_CERTIFICATE_FAILURE: 'student/GENERATE_CERTIFICATE_FAILURE',
  CLEAR_GENERATE_CERTIFICATE_STATUS: 'student/CLEAR_GENERATE_CERTIFICATE_STATUS',

  UPDATE_REVIEWS_STATE: 'student/UPDATE_REVIEWS_STATE',

  SET_LICENSEE_ACCEPTANCE_TERMS: 'student/SET_LICENSEE_ACCEPTANCE_TERMS',
  SET_COMPLETION_DATE: 'student/SET_COMPLETION_DATE',
  SET_STUDENT_PAYMENT: 'student/SET_STUDENT_PAYMENT',

  REQUEST_FAILURE: 'student/REQUEST_FAILURE',
};

import settings from '../config/settings';
const logger = require('@condor-labs/logger');

const { isAdrollEnabled, adrollAdvId, adrollPixId, adrollVersion } = settings;
const { REACT_APP_ENV = 'development' } = process.env;
let isInitialized = false;

class AdrollNotInitializedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AdrollNotInitializedError';
    this.message = message;
    this.title = 'AdRoll Not Initialized';
    this.type = 'AdRollNotInitialized';
  }
}

const verifyInit = () => {
  if (!isInitialized || !window.adroll || !window.adroll.track) {
    logger.warn('AdRoll not initialized on record user called');
    const error = new AdrollNotInitializedError(
      'AdRoll was unable to initialize. This is most commonly due to an ad blocker in the client.'
    );
    logger.error(error);
    return false;
  }
  return isInitialized;
};

export const InitAdRollTracker = () => {
  if (isAdrollEnabled && !!adrollAdvId && !!adrollPixId && REACT_APP_ENV !== 'development') {
    window.adroll_adv_id = adrollAdvId;
    window.adroll_pix_id = adrollPixId;
    window.adroll_version = adrollVersion;

    window.__adroll_loaded = true;
    window.adroll = window.adroll || [];
    window.adroll.f = ['setProperties', 'identify', 'track'];
    const roundtripUrl = `https://s.adroll.com/j/${adrollAdvId}/roundtrip.js`;
    for (let a = 0; a < window.adroll.f.length; a++) {
      window.adroll[window.adroll.f[a]] =
        window.adroll[window.adroll.f[a]] ||
        (function (n) {
          return function () {
            window.adroll.push([n, arguments]);
          };
        })(window.adroll.f[a]);
    }

    const newScript = document.createElement('script');
    const existingScript = document.getElementsByTagName('script')[0];
    newScript.async = 1;
    newScript.src = roundtripUrl;
    existingScript.parentNode.insertBefore(newScript, existingScript);
    isInitialized = true;
  }
};

export const AdRollTrack = (adRollEvent) => {
  if (!verifyInit()) {
    return;
  }

  if (!adRollEvent) {
    return logger.warn('No adroll event to track');
  }
  window.adroll.track(adRollEvent);
};

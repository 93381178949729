/* istanbul ignore file */
import at from './types';

export const fetchStudent = (data) => {
  return { type: at.FETCH_STUDENT_REQUEST, data };
};

export const updateCurrentStep = (data) => {
  return { type: at.UPDATE_CURRENT_STEP_REQUEST, data };
};

export const saveContentPagesRemainingTime = (data) => {
  return { type: at.SAVE_CONTENT_PAGES_REMAINING_TIME_REQUEST, data };
};

export const updateAverageScoreState = (totalScore) => {
  return { type: at.UPDATE_AVERAGE_SCORE_STATE, totalScore };
};

export const generateCertificate = (data) => {
  return { type: at.GENERATE_CERTIFICATE_REQUEST, data };
};

export const clearGenerateCertificateStatus = () => ({
  type: at.CLEAR_GENERATE_CERTIFICATE_STATUS,
});

export const setLicenseeAcceptanceTerms = (data) => {
  return { type: at.SET_LICENSEE_ACCEPTANCE_TERMS, data };
};

export const setCompletionDate = () => {
  return { type: at.SET_COMPLETION_DATE };
};

export const setStudentPayment = (payment) => {
  return { type: at.SET_STUDENT_PAYMENT, payment };
};

export const updateReviewsState = (data) => {
  return { type: at.UPDATE_REVIEWS_STATE, data };
};

import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export default SpinnerContainer;

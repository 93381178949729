/* istanbul ignore file */
// eslint-disable-next-line
import TimeOutWorker from 'workerize-loader!../workers/setTimeOut.worker';
import axios from './axiosConfig';
import settings from '../config/settings';

function setAuthHeader(token) {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

function getWorkerTriggeringTime(expirationTimeInSeconds) {
  const secondsBeforeExpiration = 5 * 60; // Convert 5 minutes to seconds
  const triggeringTime = expirationTimeInSeconds - secondsBeforeExpiration;
  return triggeringTime * 1000; // Convert seconds to milliseconds
}

function silentRefreshToken(expirationTime) {
  const worker = new TimeOutWorker();

  worker.onmessage = async (event) => {
    if (typeof event.data === 'boolean') {
      const isJWTExpiring = event.data;
      if (isJWTExpiring) {
        const response = await axios.request({
          url: `${settings.authEndpointUrl}/refreshtoken`,
          method: 'POST',
          withCredentials: true,
        });

        setAuthHeader(response.data.jwt.access_token);
        const triggeringTime = getWorkerTriggeringTime(response.data.jwt.expires_in);
        worker.postMessage(triggeringTime);
      }
    }
  };

  const triggeringTime = getWorkerTriggeringTime(expirationTime);
  worker.postMessage(triggeringTime);
}

function silentRefreshTokenFallback(expirationTime) {
  const triggeringTime = getWorkerTriggeringTime(expirationTime);
  setTimeout(async () => {
    const response = await axios.request({
      url: `${settings.authEndpointUrl}/refreshtoken`,
      method: 'POST',
      withCredentials: true,
    });

    setAuthHeader(response.data.jwt.access_token);
    const newTriggeringTime = getWorkerTriggeringTime(response.data.jwt.expires_in);
    silentRefreshTokenFallback(newTriggeringTime);
  }, triggeringTime);
}

async function getAccessToken(data) {
  const response = await axios.request({
    url: `${settings.authEndpointUrl}/licensee`,
    method: 'POST',
    withCredentials: true,
    data,
  });
  setAuthHeader(response.data.jwt.access_token);
  if (typeof Worker !== 'undefined') {
    silentRefreshToken(response.data.jwt.expires_in);
  } else {
    silentRefreshTokenFallback(response.data.jwt.expires_in);
  }

  return response;
}

export default {
  getAccessToken,
};

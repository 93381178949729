import styled, { css } from 'styled-components';

const Title = styled.h1`
  ${({ theme, color, allowSelection, darkMode }) => css`
    color: ${darkMode ? '#fff' : color || theme.color.black};
    font-weight: 600;
    margin: 0 !important;
    padding: 20px 15px 10px 0;
    letter-spacing: 0;

    ${allowSelection &&
    css`
      ::selection {
        background: ${theme.color.primary};
        color: #fff;
      }
    `}

    @media (min-width: ${theme.minScreen.sm}) and (max-width: ${theme.maxScreen.md}) {
      font-size: 40px;
    }

    @media (min-width: ${theme.minScreen.md}) {
      font-size: 48px;
    }
  `}
`;
Title.displayName = 'Title';

export default Title;

/* istanbul ignore file */
import axios from './axiosConfig';
import settings from '../config/settings';

export const fetchQuizzes = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/quizzesVersions`,
    method: 'GET',
    params: data,
    withCredentials: true,
  });
};

export const saveQuizAnswers = (quizId, data) => {
  return axios.request({
    url: `${settings.APIUrl}/quizzesVersions/${quizId}/completions`,
    method: 'POST',
    data,
    withCredentials: true,
  });
};

/* istanbul ignore file */
export default function (globalState) {
  const state = globalState.student;

  const getStudent = () => state.student;
  const getFetchStudentStatus = () => state.fetchStudentStatus;
  const getErrors = () => state.errorStatus;
  const getGenerateCertificateStatus = () => state.generateCertificateStatus;

  return {
    getStudent,
    getFetchStudentStatus,
    getErrors,
    getGenerateCertificateStatus,
  };
}

import styled from 'styled-components';

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
`;

export const Image = styled.img`
  @media (min-width: 873px) {
    width: 455px;
  }
  @media (max-width: 872px) {
    width: 420px;
  }

  @media (max-width: 530px) {
    width: 350px;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  font-weight: 400;
  color: #555;

  @media (min-width: 531px) {
    font-size: 22px;
  }
  @media (min-width: 873px) {
    padding-left: 25%;
    padding-right: 25%;
  }
  @media (max-width: 872px) {
    padding-left: 10%;
    padding-right: 10%;
  }

  @media (max-width: 530px) {
    font-size: 17px;
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  margin-top: 25px;

  @media (min-width: 531px) {
    font-size: 20px;
  }
  @media (max-width: 530px) {
    font-size: 17px;
  }
`;

export const Title = styled.h1`
  margin: 35px 0 40px 0;
  color: #555;

  @media (min-width: 531px) {
    font-size: 40px;
  }
  @media (max-width: 530px) {
    font-size: 32px;
  }
`;

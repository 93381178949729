/* istanbul ignore file */
import axios from './axiosConfig';
import settings from '../config/settings';

export const fetchStudentById = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/students/`,
    method: 'GET',
    params: data,
  });
};

export const updateCurrentStep = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/students/${data.studentId}/currentStep`,
    method: 'PATCH',
    data: { currentStep: data.currentStep },
  });
};

export const saveContentPagesRemainingTime = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/students/${data.studentId}/contentPagesRemainingTime`,
    method: 'PATCH',
    data: data.studentData,
  });
};

export const payCourse = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/students/${data.studentId}/payment`,
    method: 'POST',
    data: data.paymentData,
    withCredentials: true,
  });
};

export const saveCourseAverageScore = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/students/${data}/averageScore`,
    method: 'POST',
    withCredentials: true,
  });
};

export const retryCourseAverageScore = (data) => {
  const { studentId, isPollingJob, timeout } = data;
  return axios.request({
    url: `${settings.APIUrl}/students/${studentId}/retryAverageScore`,
    method: 'POST',
    params: { isPollingJob },
    withCredentials: true,
    timeout,
  });
};

export const generateCertificate = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/students/${data.studentId}/certificate/generate`,
    method: 'POST',
    data: data.certificateData,
    withCredentials: true,
  });
};

export const fetchApplicableHours = (studentId) => {
  return axios.request({
    url: `${settings.APIUrl}/students/${studentId}/applicableHours`,
    method: 'GET',
    withCredentials: true,
  });
};

/* istanbul ignore file */
import { put } from 'redux-saga/effects';
import at from '../actions/types';

const defaultUser = {
  firstName: 'John',
  lastName: 'Anderson',
  userName: 'support-mytablemesa@condorlabs.io',
};

export default function* fetch() {
  try {
    // const result = yield call(api.fetchLoggedInUser);
    // yield put({ type: at.FETCH_SUCCESS, user: result.data });
    yield put({ type: at.FETCH_SUCCESS, user: defaultUser });
  } catch (error) {
    /* if (error.response && error.response.status === 401) {
            // Redirect to CE
            let url = `${config.API_URL}account/login`;
            if (window.location.pathname && window.location.pathname !== '/') {
                url = window.location.pathname === '/'
                    ? url
                    : `${url}?ReturnUrl=${encodeURIComponent(window.location.pathname)}`;
            }
            window.location.href = url;
        } */
    yield put({ type: at.FETCH_FAILURE });
  }
}

/* istanbul ignore file */
export default function (globalState) {
  const state = globalState.content;

  const getCourseContent = () => state.steps;
  const getCourseContentStatus = () => state.fetchCourseContentStatus;
  const getCreateCourseStepsStatus = () => state.createCourseStepsStatus;
  const getUpdateTestAnswersStateStatus = () => state.updateTestAnswersStateStatus;
  const getQuizAnswersStatus = () => state.saveQuizAnswersStatus;
  const getSurveyAnswersStatus = () => state.saveSurveyAnswersStatus;
  const getErrors = () => state.errorStatus;
  const getCreateStandardizedPageLaunchUrlStatus = () => state.createStandardizedPageLaunchUrlStatus;
  const getStandardizedPageLaunchUrlStatus = () => state.fetchStandardizedPageLaunchUrlStatus;

  return {
    getCourseContent,
    getCourseContentStatus,
    getCreateCourseStepsStatus,
    getUpdateTestAnswersStateStatus,
    getQuizAnswersStatus,
    getSurveyAnswersStatus,
    getCreateStandardizedPageLaunchUrlStatus,
    getStandardizedPageLaunchUrlStatus,
    getErrors,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, Paragraph, Link, Image, Title } from './Unauthorized.styled';
import settings from '../../config/settings';

const Unauthorized = ({ backLink }) => (
  <Overlay>
    <Image src={`https://${settings.assetsBucketName}.s3.amazonaws.com/images/401.svg`} alt="Not authorized" />
    <Title>Hold your horses!</Title>
    <Paragraph>
      For some reason you ended up in the wrong place at the wrong time. Maybe you don&apos;t have the privileges to see
      this page.
    </Paragraph>
    <Link href={backLink}>Go Back</Link>
  </Overlay>
);

Unauthorized.propTypes = {
  backLink: PropTypes.string.isRequired,
};

export default Unauthorized;

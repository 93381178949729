import React from 'react';
import styled, { css } from 'styled-components';
import { Modal } from 'emerald-ui';

const ModalContainer = styled((props) => {
  const newProps = { ...props };
  delete newProps.darkMode;

  return <Modal {...newProps} />;
})`
  ${({ theme, darkMode }) => css`
    padding-top: 50px;
    z-index: 1200;

    ${darkMode &&
    css`
      .eui-modal-dialog {
        background-color: ${theme.color.aquamarine.variant3};
      }

      .row div p {
        color: #fff;
      }

      .eui-modal-body {
        h2 {
          color: #fff;
        }
        span {
          color: #fff;
        }
      }
    `}

    .modal-footer {
      justify-content: center;
      border-top: none;
    }

    .modal-dialog {
      pointer-events: unset;
      max-width: unset;
    }

    @media (min-width: ${theme.minScreen && theme.minScreen.sm}) {
      padding-top: 150px;
    }
  `};
`;
ModalContainer.displayName = 'ModalContainer';

export default ModalContainer;

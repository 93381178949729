/* istanbul ignore file */
import axios from './axiosConfig';
import settings from '../config/settings';

export const fetchStandardizedPages = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/standardizedPagesVersions`,
    method: 'GET',
    params: data,
    withCredentials: true,
  });
};

export const getStandardizedPageVersionCompletion = ({ standardizedPageVersionId }) => {
  return axios.request({
    url: `${settings.APIUrl}/standardizedPagesVersions/${standardizedPageVersionId}/completion/status`,
    method: 'GET',
    withCredentials: true,
  });
};

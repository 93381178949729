/* istanbul ignore file */
export const courseFlowTheme = {
  color: {
    gray: {
      variant1: '#fcfcfe',
      variant2: '#eceaea',
      variant3: '#afafaf',
      variant4: '#82888a',
      variant5: '#333333',
      variant6: '#282e2e',
    },
    aquamarine: {
      variant1: '#eaffff',
      variant2: '#607b7b',
      variant3: '#233239',
    },
    primary: '#0073e9',
    black: '#1d2121',
    red: '#d9534f',
    green: '#2ec46c',
  },
  size: {
    xs: '5px',
    sm: '10px',
    md: '20px',
    lg: '30px',
    xl: '40px',
  },
  minScreen: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  maxScreen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
  boxShadow: {
    variant1: '0 3px 5px rgba(218, 218, 231, 0.49), 0 0 5px rgba(0, 0, 0, 0.15)',
    variant2: '0 3px 5px rgba(218, 218, 231, 0.49), 0 0 5px rgba(218, 218, 231, 0.49)',
  },
};

export default {
  courseFlowTheme,
};

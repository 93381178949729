/* istanbul ignore file */
import { combineReducers } from 'redux';
import { rootReducer as content } from './content';
import { rootReducer as student } from './student';
import { rootReducer as loggedInUser } from './loggedInUser';
import { rootReducer as darkMode } from './darkMode';

const rootReducer = combineReducers({
  content,
  student,
  loggedInUser,
  darkMode,
});

export default rootReducer;

/* istanbul ignore file */
import { call, put, all } from 'redux-saga/effects';
import * as apiContentPagesVersions from '../../../api/contentPagesVersions';
import * as apiTestsVersions from '../../../api/testsVersions';
import * as apiSurveysVersions from '../../../api/surveysVersions';
import * as apiQuizzesVersions from '../../../api/quizzesVersions';
import * as apiStandardizedPagesVersions from '../../../api/standardizedPagesVersions';
import * as apiStandardizedPageLaunchUrl from '../../../api/standardizedPageLaunchUrls';
import at from '../actions/types';
import { getErrorMessage } from '../../../helpers/errorsHelper';

const defaultErrorMessage = 'Oops! something went wrong!';

function* fetchCourseContent(data) {
  try {
    const [contentPages, tests, surveys, quizzes, standardizedPages] = yield all([
      call(apiContentPagesVersions.fetchContentPages, data.contentPagesData),
      call(apiTestsVersions.fetchTests, data.testData),
      call(apiSurveysVersions.fetchSurveys, data.surveyData),
      call(apiQuizzesVersions.fetchQuizzes, data.quizData),
      call(apiStandardizedPagesVersions.fetchStandardizedPages, data.standardizedPageData),
    ]);
    yield put({
      type: at.FETCH_COURSE_CONTENT_SUCCESS,
      steps: [contentPages, tests, surveys, quizzes, standardizedPages],
    });
  } catch (error) {
    yield put({ type: at.FETCH_COURSE_CONTENT_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.FETCH_COURSE_CONTENT_REQUEST,
    });
  }
}

function* saveQuizAnswers(data) {
  try {
    yield call(apiQuizzesVersions.saveQuizAnswers, data.payload.quizId, data.payload.data);
    yield put({ type: at.SAVE_QUIZ_ANSWERS_SUCCESS, data: data.payload });
  } catch (error) {
    yield put({ type: at.SAVE_QUIZ_ANSWERS_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.SAVE_QUIZ_ANSWERS_REQUEST,
    });
  }
}

function* saveSurveyAnswers(data) {
  try {
    yield call(apiSurveysVersions.saveSurveyAnswers, data.payload);
    yield put({
      type: at.SAVE_SURVEY_ANSWERS_SUCCESS,
      data: data.payload,
    });
  } catch (error) {
    yield put({ type: at.SAVE_SURVEY_ANSWERS_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.SAVE_SURVEY_ANSWERS_REQUEST,
    });
  }
}

function* createStandardizedPageLaunchUrl(data) {
  try {
    const result = yield call(apiStandardizedPageLaunchUrl.createLaunchUrl, data.payload);
    yield put({
      type: at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS,
      data: { ...result.data, ...data.payload },
    });
  } catch (error) {
    yield put({ type: at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST,
    });
  }
}

function* fetchStandardizedPageLaunchUrl(data) {
  try {
    const result = yield call(apiStandardizedPageLaunchUrl.fetchLaunchUrl, data.payload);
    yield put({
      type: at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_SUCCESS,
      data: { ...result.data, ...data.payload },
    });
  } catch (error) {
    yield put({ type: at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST,
    });
  }
}

export default {
  fetchCourseContent,
  saveQuizAnswers,
  saveSurveyAnswers,
  createStandardizedPageLaunchUrl,
  fetchStandardizedPageLaunchUrl,
};

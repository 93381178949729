import React from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';
import settings from '../../config/settings';

const { isReactGAEnabled, GATrackingId, GATrackingMetricsId } = settings;
const { REACT_APP_ENV = 'development', REACT_APP_GA_AUTOLINK_SITES } = process.env;

function InitGATracker() {
  const canTrackPage = isReactGAEnabled && !!GATrackingId;
  if (canTrackPage) {
    if (REACT_APP_ENV !== 'development') {
      ReactGA.initialize(
        [
          {
            trackingId: GATrackingId,
            gaOptions: {},
          },
        ],
        { titleCase: false }
      );

      ReactGA.plugin.require('linker');
      ReactGA.plugin.execute('linker', 'autolink', [REACT_APP_GA_AUTOLINK_SITES]);
    }
  }
}

function GAPagesTracker(WrappedComponent, options = {}) {
  const canTrackPage = isReactGAEnabled && !!GATrackingId;
  const trackPage = (page) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page, ['productsOperationLoad']);
  };

  class GATracker extends React.Component {
    componentDidMount() {
      const { location } = window;
      if (canTrackPage && location) {
        const page = `${location.pathname}${location.search}`;
        trackPage(page);
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (canTrackPage) {
        const currentPathname = this.props.location.pathname;
        const nextPathname = nextProps.location.pathname;
        if (nextProps.history.action === 'PUSH' && currentPathname !== nextPathname) {
          const nextSearch = nextProps.location.search;
          const page = `${nextPathname}${nextSearch}`;
          trackPage(page);
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  GATracker.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  };

  return GATracker;
}

function InitGATrackerMetrics() {
  const canTrackPage = isReactGAEnabled && GATrackingMetricsId;
  if (canTrackPage && REACT_APP_ENV !== 'development') {
    ReactGA.initialize(GATrackingMetricsId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

export { InitGATracker, GAPagesTracker, InitGATrackerMetrics };

/* istanbul ignore file */
import { parseStringToBoolean } from '../helpers/utils';

const {
  REACT_APP_API_URL,
  REACT_APP_ASSETS_BUCKET,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_IS_ENABLED_LICENSE_CERTIFICATION_STEP,
  REACT_APP_IS_ENABLED_INTERCOM,
  REACT_APP_IS_REQUIRED_LICENSEE_ACCEPTANCE_TERMS,
  REACT_APP_ASSETS_DOMAIN,
  REACT_APP_USE_AWS_LOCALSTACK,
  REACT_APP_DEFAULT_COURSE_IMAGE,
  REACT_APP_FINISH_COURSE_URL,
  REACT_APP_IS_ENABLED_ADDING_CREDIT_CARD,
  REACT_APP_HOME_BUTTON_ICON,
  REACT_APP_HOME_BUTTON_IMAGE_URL,
  REACT_APP_HOME_BUTTON_LINK_URL,
  REACT_APP_NOT_AVAILABLE_BACK_URL,
  REACT_APP_HELP_LINK_URL,
  REACT_APP_COURSE_SEARCH_URL,
  REACT_APP_IS_VISIBLE_CREDITS_LABEL,
  REACT_APP_IS_ENABLED_GA,
  REACT_APP_GA_TRACK_ID,
  REACT_APP_GA_TRACK_METRICS_ID,
  REACT_APP_LOGROCKET_ENABLED,
  REACT_APP_LOGROCKET_APPID,
  REACT_APP_LOGROCKET_BLACKLIST,
  REACT_APP_CERTIFICATE_APPLICABLE_HOURS_REQUIRED,
  REACT_APP_AUTH_ENDPOINT_URL,
  REACT_APP_APM_SERVER_URL,
  REACT_APP_CLIENT_LOGIN_URL,
  REACT_APP_COURSES_IN_PROGRESS_URL,
  REACT_APP_IS_ENABLED_GTM,
  REACT_APP_GTM_TRACK_ID,
  REACT_APP_ADROLL_ADV_ID,
  REACT_APP_ADROLL_PIX_ID,
  REACT_APP_ADROLL_VERSION,
  REACT_APP_IS_ENABLED_ADROLL,
  REACT_APP_SC_MODAL_MESSAGE,
  REACT_APP_TIMEOUT_VALIDATION = 6000,
  REACT_APP_CONFIRM_MODAL_MESSAGE,
  REACT_APP_SC_SECONDS_RETRY = 2000,
  REACT_APP_SC_SECONDS_RETRY_LIMIT = 60000,
  REACT_APP_SC_RESOURCE_TEXT,
  REACT_APP_SC_RESOURCE_LINK,
  REACT_APP_IS_ACTIVE_SC_RESOURCE_LINK = false,
  REACT_APP_COURSE_FLOW_URL,
  REACT_APP_YOUTUBE_CHAT_URL,
  REACT_APP_TYPE_COURSE,
} = process.env;

// Production and shared settings
const settings = {
  APIUrl: REACT_APP_API_URL,
  assetsBucketName: REACT_APP_ASSETS_BUCKET,
  stripePublishableKey: REACT_APP_STRIPE_PUBLISHABLE_KEY,
  intercomAppID: REACT_APP_INTERCOM_APP_ID,
  isEnabledLicenseCertificationStep: parseStringToBoolean(REACT_APP_IS_ENABLED_LICENSE_CERTIFICATION_STEP),
  isIntercomEnabled: parseStringToBoolean(REACT_APP_IS_ENABLED_INTERCOM),
  isLicenseeAcceptanceTermsRequired: parseStringToBoolean(REACT_APP_IS_REQUIRED_LICENSEE_ACCEPTANCE_TERMS),
  assetsDomain: REACT_APP_ASSETS_DOMAIN, // In local environment this could be used by localstack
  useAWSLocalstack: parseStringToBoolean(REACT_APP_USE_AWS_LOCALSTACK),
  defaultCourseImage: REACT_APP_DEFAULT_COURSE_IMAGE,
  finishCourseUrl: REACT_APP_FINISH_COURSE_URL,
  isEnabledAddingCreditCard: parseStringToBoolean(REACT_APP_IS_ENABLED_ADDING_CREDIT_CARD),
  homeButtonIcon: REACT_APP_HOME_BUTTON_ICON,
  homeButtonImageUrl: REACT_APP_HOME_BUTTON_IMAGE_URL,
  homeButtonLinkUrl: REACT_APP_HOME_BUTTON_LINK_URL,
  notAvailableBackUrl: REACT_APP_NOT_AVAILABLE_BACK_URL,
  helpLinkUrl: REACT_APP_HELP_LINK_URL,
  courseSearchUrl: REACT_APP_COURSE_SEARCH_URL,
  isVisibleCreditsLabel: parseStringToBoolean(REACT_APP_IS_VISIBLE_CREDITS_LABEL),
  isReactGAEnabled: parseStringToBoolean(REACT_APP_IS_ENABLED_GA),
  GATrackingId: REACT_APP_GA_TRACK_ID,
  GATrackingMetricsId: REACT_APP_GA_TRACK_METRICS_ID,
  logRocketEnabled: parseStringToBoolean(REACT_APP_LOGROCKET_ENABLED),
  logRocketAppId: REACT_APP_LOGROCKET_APPID,
  logRocketBlacklist: REACT_APP_LOGROCKET_BLACKLIST ? REACT_APP_LOGROCKET_BLACKLIST.split(',') : [],
  certificateApplicableHoursRequired: parseStringToBoolean(REACT_APP_CERTIFICATE_APPLICABLE_HOURS_REQUIRED),
  authEndpointUrl: REACT_APP_AUTH_ENDPOINT_URL,
  apmServerUrl: REACT_APP_APM_SERVER_URL,
  clientLoginUrl: REACT_APP_CLIENT_LOGIN_URL,
  coursesInProgressUrl: REACT_APP_COURSES_IN_PROGRESS_URL,
  isReactGTMEnabled: parseStringToBoolean(REACT_APP_IS_ENABLED_GTM),
  GTMTrackingId: REACT_APP_GTM_TRACK_ID,
  isAdrollEnabled: REACT_APP_IS_ENABLED_ADROLL,
  adrollAdvId: REACT_APP_ADROLL_ADV_ID,
  adrollPixId: REACT_APP_ADROLL_PIX_ID,
  adrollVersion: REACT_APP_ADROLL_VERSION,
  scModalMessage: REACT_APP_SC_MODAL_MESSAGE,
  confirmModalMessage: REACT_APP_CONFIRM_MODAL_MESSAGE,
  timeoutValidation: Number(REACT_APP_TIMEOUT_VALIDATION),
  timeoutSCRetryStatus: Number(REACT_APP_SC_SECONDS_RETRY),
  timeoutSCRetryStatusLimit: Number(REACT_APP_SC_SECONDS_RETRY_LIMIT),
  scResourceText: REACT_APP_SC_RESOURCE_TEXT,
  scResourceLink: REACT_APP_SC_RESOURCE_LINK,
  isActiveScResourceLink: REACT_APP_IS_ACTIVE_SC_RESOURCE_LINK,
  courseFlowUrl: REACT_APP_COURSE_FLOW_URL,
  liveCourse: REACT_APP_TYPE_COURSE,
  youtubeChatUrl: REACT_APP_YOUTUBE_CHAT_URL,
  ratingDescriptions: [
    'Poor, not what I expected',
    'Below average, could be better',
    'Average',
    'Good, what I expected',
    'Amazing, above expectations!',
  ],
};

export default settings;

import React, { useState } from 'react';
import AlertBox from './AlertBox';

const defaultData = {
  type: '',
  title: '',
  text: '',
  confirmButtonText: '',
  cancelButtonText: '',
  onConfirm: () => {},
  onCancel: () => {},
};

export function useAlertBox() {
  const [data, setData] = useState(defaultData);
  const [show, setShow] = useState(false);

  const onConfirm = () => {
    setShow(false);
    if (data.onConfirm) {
      data.onConfirm();
    }
  };

  const onCancel = () => {
    setShow(false);
    if (data.onCancel) {
      data.onCancel();
    }
  };

  const display = (config) => {
    setData(config);
    setShow(true);
  };

  const content = () => (
    <AlertBox
      show={show}
      type={data.type}
      title={data.title}
      text={data.text}
      confirmButtonText={data.confirmButtonText}
      cancelButtonText={data.cancelButtonText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      darkMode={data.darkMode}
    />
  );

  return { content, display };
}

export default useAlertBox;

/* istanbul ignore file */
import axios from './axiosConfig';
import settings from '../config/settings';

export const fetchTests = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/testsVersions`,
    method: 'GET',
    params: data,
    withCredentials: true,
  });
};

export const saveTestAnswers = (data) => {
  return axios.request({
    url: `${settings.APIUrl}/testsVersions/${data.testId}/completions`,
    method: 'POST',
    data: data.body,
    withCredentials: true,
  });
};

/* istanbul ignore file */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { darkModeSelectors } from '../store/darkMode';
import SessionModal from '../components/SessionModal';

function mapStateToProps(state) {
  const darkMode = darkModeSelectors(state);
  return {
    darkMode: darkMode.getDarkMode(),
  };
}

export default withRouter(connect(mapStateToProps, {})(SessionModal));

export const parseStringToBoolean = (value) => {
  const newValue = value ? value.toLowerCase() : value;
  return newValue === 'true';
};

export const buildAWSAssetsUrl = (assetsDomain, assetsBucketName) => {
  const urlMainPattern = assetsDomain.replace(/(^\w+:|^)\/\//, '');
  const startUrlIndex = assetsDomain.indexOf(urlMainPattern);
  return `${assetsDomain.substr(0, startUrlIndex)}${assetsBucketName}.${urlMainPattern}`;
};

export const extractVideoIdFromHTML = (contentHTML) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(contentHTML, 'text/html');
  const iframeElement = doc.querySelector('iframe');

  if (iframeElement) {
    const src = iframeElement.getAttribute('src');
    return src.substring(src.lastIndexOf('/') + 1);
  }

  return null;
};

/* istanbul ignore file */
import { call, put } from 'redux-saga/effects';
import * as studentsApi from '../../../api/students';
import at from '../actions/types';
import { getErrorMessage } from '../../../helpers/errorsHelper';

const defaultErrorMessage = 'Oops! something went wrong!';

function* fetchStudentById({ data }) {
  try {
    const result = yield call(studentsApi.fetchStudentById, data);
    if (result.data) {
      yield put({ type: at.FETCH_STUDENT_SUCCESS, student: result.data });
    } else {
      yield put({ type: at.FETCH_STUDENT_NOT_FOUND });
    }
  } catch (error) {
    yield put({ type: at.FETCH_STUDENT_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.FETCH_STUDENT_REQUEST,
    });
  }
}

function* updateCurrentStep({ data }) {
  try {
    yield call(studentsApi.updateCurrentStep, data);
    yield put({ type: at.UPDATE_CURRENT_STEP_SUCCESS, data });
  } catch (error) {
    yield put({ type: at.UPDATE_CURRENT_STEP_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.UPDATE_CURRENT_STEP_REQUEST,
    });
  }
}

function* saveContentPagesRemainingTime({ data }) {
  try {
    yield call(studentsApi.saveContentPagesRemainingTime, data);
    yield put({ type: at.SAVE_CONTENT_PAGES_REMAINING_TIME_SUCCESS, data });
  } catch (error) {
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.SAVE_CONTENT_PAGES_REMAINING_TIME_REQUEST,
    });
  }
}

function* generateCertificate({ data }) {
  try {
    const result = yield call(studentsApi.generateCertificate, data);
    yield put({ type: at.GENERATE_CERTIFICATE_SUCCESS, data: result.data });
  } catch (error) {
    yield put({ type: at.GENERATE_CERTIFICATE_FAILURE });
    yield put({
      type: at.REQUEST_FAILURE,
      data,
      message: getErrorMessage(error, defaultErrorMessage),
      requestFailed: at.GENERATE_CERTIFICATE_REQUEST,
    });
  }
}

export default {
  fetchStudentById,
  updateCurrentStep,
  saveContentPagesRemainingTime,
  generateCertificate,
};

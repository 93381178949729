/* istanbul ignore file */
import { takeLatest } from 'redux-saga/effects';
import at from '../actions/types';
import content from './content';

export default function* root() {
  yield takeLatest(at.FETCH_COURSE_CONTENT_REQUEST, content.fetchCourseContent);
  yield takeLatest(at.SAVE_QUIZ_ANSWERS_REQUEST, content.saveQuizAnswers);
  yield takeLatest(at.SAVE_SURVEY_ANSWERS_REQUEST, content.saveSurveyAnswers);
  yield takeLatest(at.CREATE_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST, content.createStandardizedPageLaunchUrl);
  yield takeLatest(at.FETCH_STANDARDIZED_PAGE_LAUNCH_URL_REQUEST, content.fetchStandardizedPageLaunchUrl);
}
